<template>
  <div class="card">
    <div class="card-body">
      <CRow>
        <CCol sm="12">
          <FullCalendar :options="calendarOptions" />
        </CCol>
      </CRow>

      <!-- modal view detail date -->
    <CModalForm
      :size="'lg'"
      :title="modal_view_det_date.title"      
      :button="false"
      :show.sync="modal_view_det_date.modal_form"
      :show_boton="false"
      @mtd_action="modal_action"
    >
      <CRow>
        <CCol sm="12">
          <table class="table table-striped table-bordered">
              <thead>
                  <tr>
                      <th>Hora Salida</th>
                      <th>Cliente</th>
                      <th>Estado</th>
                      <th>Acción</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(item, index) in body_table" :key="index">
                      <td>{{item.hour}}</td>
                      <td>{{item.client}} </td>
                      <td v-if="item.state == 1"> <span class="badge float-right badge-warning">Registrado</span> </td>
                      <td v-if="item.state == 2"> <span class="badge float-right badge-success">Aceptado</span> </td>
                      <td v-if="item.state == 3"> <span class="badge float-right badge-danger">Cancelado</span> </td>
                      <td>
                          <button v-if="item.state != 2" class="btn btn-sm btn-success" @click="mtd_updated_state(item.id,2)">Aceptar </button>
                          <button v-if="item.state != 3" class="btn btn-sm btn-danger ml-2" @click="mtd_updated_state(item.id,3)">Anular </button>
                      </td>
                  </tr>
              </tbody>
          </table>
        </CCol>        
      </CRow>
    </CModalForm>
    </div>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";

import { mapActions } from "vuex";
import { bus } from '../../main';
import CModalForm from "../../components/shared/modals/cModalForm.vue";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CModalForm
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        locale: esLocale,
        headerToolbar: {
          left: "title",
          right: "today dayGridMonth",
        },
        events: [],
        eventClick: this.handleEventClick,
        dateClick: this.handleDateClick
      },
      modal_view_det_date:{        
        title: "",
        modal_form: false,
      },
      body_table :[]
    };
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata() {
      this.get({
        url: this.$store.getters.get__url + "/visit/scheduled",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.calendarOptions.events = response;
        })
        .catch((errors) => {});
    },
    handleEventClick: function(arg){
      console.log(arg.event);
      const date = new Date(arg.event.start);
      const dateCurrent = new Date();
      dateCurrent.setHours(0); dateCurrent.setMinutes(0); dateCurrent.setSeconds(0); dateCurrent.setMilliseconds(0);
      if(date < dateCurrent){
        bus.$emit('alert', { 
            color:'danger',
            message:'No puede Seleccionar fechas pasadas'
          });
      }else{
        this.post({
            url: this.$store.getters.get__url + "/visit/detail_date",
            token: this.$store.getters.get__token,
            params: arg.event.extendedProps
        })
        .then((response) => {            
            this.modal_view_det_date.title="Visitas al proyecto "+response.project_name+" del día "+ response.date;
            this.modal_view_det_date.modal_form = true;
            this.body_table = response.data;
        })
        .catch((errors) => {});
      }
    },
    handleDateClick: function(){
      // alert('aqui reporte xD');
    },
    modal_action:function(){        
        this.modal_view_det_date.title="";
        this.modal_view_det_date.modal_form = false;
    },
    mtd_updated_state: function(id,state){
        this.get({
            url: this.$store.getters.get__url + "/visit/"+id+"/"+state+"/update_state_visit",
            token: this.$store.getters.get__token
        })
        .then((response) => {
            this.body_table.forEach(element => {
                if(element.id == id) element.state = state;
            });
        })
        .catch((errors) => {});
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
